import { createAction, props } from '@ngrx/store';
import {NotificationItem} from "@revolve-app/app/shared/notifier/notifier-item/notifier-item.component";

export const NOTIFICATION_COMPONENT_ADD = '[Notification component] Add';
export const NOTIFICATION_COMPONENT_REMOVE = '[Notification component] Remove';

export const addNotification = createAction(
  NOTIFICATION_COMPONENT_ADD,
  props<{payload: NotificationItem}>()
);

export const removeNotification = createAction(
  NOTIFICATION_COMPONENT_REMOVE,
  props<{payload: {id: string}}>() // Replace `any` with your user model
);
