@if (notification && show){
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <!-- Icon (CheckCircleIcon replacement with Angular Material or similar) -->
          <span class="h-6 w-6 text-green-400">
            @switch (notification.type){
              @case (NotificationTypes.info){
                𝒾
              }
              @case (NotificationTypes.error){
                ✘
              }
              @case (NotificationTypes.success){
                ✔
              }
            }

          </span>
        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium text-gray-900">{{notification.title}}</p>
          <p class="mt-1 text-sm text-gray-500" [innerHTML]="notification.description"></p>
        </div>
        <div class="ml-4 flex flex-shrink-0">
          <button type="button"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  (click)="removeNotification(notification.id)">
            <span class="sr-only" i18n>Close</span>
            <span class="h-5 w-5">✖️</span>
          </button>
        </div>
      </div>
    </div>
}
