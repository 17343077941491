import {Component, Input, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import { removeNotification } from '../store/notification.actions';
export interface NotificationItem{
  id?: string,
  title?: string,
  description?: string,
  type?: NotificationTypes
}
export enum NotificationTypes {
  success,
  error,
  info
}
@Component({
  selector: 'app-notifier-item-container',
  standalone: true,
  templateUrl: './notifier-item.component.html',
  styleUrl: './notifier-item.component.scss'
})
export class NotifierItemComponent {
  @Input() notification: NotificationItem = {
    id: '1',
    title: 'Info title',
    description: 'description of notification',
    type: NotificationTypes.info
  }
  constructor(private storeService: Store) {
    setTimeout(()=> {
      if(this.notification.id){
        this.removeNotification(this.notification.id)
      }
    },10000)
  }

  show: boolean = true;


  removeNotification(id: string | undefined){
    if(id){
      this.storeService.dispatch(removeNotification({payload: {id}}))
    }
  }

  protected readonly NotificationTypes = NotificationTypes;
}
